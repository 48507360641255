<template>
  <div>
    <!--begin::Events-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Training Videos</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="addTrainingVideo()">
                  Add Video
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <v-data-table
          :headers="headers"
          :items="training_videos"
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td> {{ item.name }} </td>
              <td>{{ item.description }}</td>
              <td> 
                  <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="redirectToTrainingVideo(item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-play-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Play Training Video</span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="trainingVideoEdit(item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Training Video</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="trainingVideoDelete(item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Training Video</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <!--end::Events-->
  </div>
</template>

<script>
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { DELETE_TRAINING_VIDEO } from "@/core/services/store/committee.module";

export default {
  name: "TrainingVideoList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      training_videos: [],
      search: "",
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Description", value: "description", width: "35%" },
        { text: "Video", value: "video_path", width: "20%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ]
    };
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Training Video" }]);
    this.getTrainingVideoList();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    redirectToTrainingVideo(video_id) {
      window.open(process.env.VUE_APP_DOC_URL + "api/download-video/" + video_id);
    },
    trainingVideoDelete(id) {
      let context = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Training Video details!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_TRAINING_VIDEO, id).then(() => {
            this.getTrainingVideoList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Training video is safe.", "", "info");
        }
      });
    },
    trainingVideoEdit(training_video_id) {
      this.$router.push({
        name: "trainingvideo.edit",
        params: { id: training_video_id }
      });
    },
    getTrainingVideoList() {
      let context = this;
      axios({
        method: "get",
        url: "training-video",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.training_videos = result.data.training_videos;
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Training Video list",
            "info"
          );
        }
      );
    },
    addTrainingVideo() {
      let context = this;
      context.$router.push({ name: "trainingvideoCreate" });
    }
  }
};
</script>
